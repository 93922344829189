import { CONST_STATES } from "../../data/usStates";

const GeneralQuestionnaireStructure = [
    /* {
        title: null,
        subtitle: null,
        questions: [
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'How many Irrevocable Trusts?',
                field: 'estateQuestion___irrevNumber',
                version: [1, 2, 3, 4],
                type: 'number',
                visible: false,
            },
        ],
    }, */
    {
        title: 'Will',
        subtitle: null,
        questions: [
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Has will?',
                field: 'estateQuestion___will_hasWill',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is it a pour-over will?',
                field: 'estateQuestion___will_pourOver',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is the will dated more than 5 years ago?',
                field: 'estateQuestion___will_dated5YearsAgo',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is the state listed as the "governing law provision" in the will document different than the state of residence in the General Questionnaire?',
                field: 'estateQuestion___will_diffGoverningLaw',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'Will',
        subtitle: 'Executors',
        questions: [
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Who are the executors?',
                field: 'estateQuestion___will_executors',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Who are the successor executors?',
                field: 'estateQuestion___will_successorExecutors',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Are there co-executors/multiple executors?',
                field: 'estateQuestion___will_coExecutors',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Are there contingent beneficiaries in the will?',
                field: 'estateQuestion___will_hasContingentBeneficiaries',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'Will',
        subtitle: 'Minor Children',
        questions: [
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Did the client name a guardian for their minor children?',
                field: 'estateQuestion___will_guardianForMinors',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there a minor’s clause in the will?',
                field: 'estateQuestion___will_minorClause',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'Will',
        subtitle: 'Fiduciary Powers',
        questions: [
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is the will owner a business owner?',
                field: 'estateQuestion___will_businessOwner',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there a digital assets provision allowing the executor to manage online accounts and data?',
                field: 'estateQuestion___will_digitalAssets',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'Will',
        subtitle: 'Distributions',
        questions: [
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Are there any specific distributions (specific bequests)?',
                field: 'estateQuestion___will_specificDistributions',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there a cash bequest?',
                field: 'estateQuestion___will_cashBequest',
                version: [1, 2, 3, 4],
                indent: 8
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is the specific bequest a house or real estate property?',
                field: 'estateQuestion___will_realEstate',
                version: [1, 2, 3, 4],
                indent: 8
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Which Property & Beneficiaries?',
                field: 'estateQuestion___will_realEstateDetail_JSON',
                version: [1, 2, 3, 4],
                indent: 8
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is the specific bequest personal property? (cars, jewelry, art, collectibles, furniture, dishes, etc)',
                field: 'estateQuestion___will_personalProperty',
                version: [1, 2, 3, 4],
                indent: 8
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Which Property & Beneficiaries?',
                field: 'estateQuestion___will_personalPropertyDetail_JSON',
                version: [1, 2, 3, 4],
                indent: 8
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is the specific bequest a business?',
                field: 'estateQuestion___will_business',
                version: [1, 2, 3, 4],
                indent: 8
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Who are the beneficiaries of the residuary clause?',
                field: 'estateQuestion___will_residuaryBeneficiaries',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'What is the exact wording of residuary clause?',
                field: 'estateQuestion___will_residuaryClause',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Per capita', 'By right of representation', 'Per stirpes'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Who are the contingent beneficiaries?',
                field: 'estateQuestion___will_contingentBeneficiaries',
                version: [1, 2, 3, 4],
                addOptions: true,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there a catastrophe clause?',
                field: 'estateQuestion___will_catastropheClause',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Are there any charitable distributions?',
                field: 'estateQuestion___will_charitableDistributions',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there direction on which assets should be used for the charitable distribution?',
                field: 'estateQuestion___will_assetDirections',
                version: [1, 2, 3, 4],
                indent: 8
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Does the Will contain any provisions that address managing business interest?',
                field: 'estateQuestion___will_hasBusinessManagementProvisions',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'Will',
        subtitle: 'Testamentary Trusts',
        questions: [
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Does the will create a testamentary trust at the death of the will owner?',
                field: 'estateQuestion___will_testamentaryTrust',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Does the will create a trust that provides for distributions under a HEMS standard?',
                field: 'estateQuestion___will_hemStandardTrust',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is the testamentary trust created at death a disclaimer or bypass trust?',
                field: 'estateQuestion___will_disclaimerTrust',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: false,
                isDynamic: false,
                label: 'Is there a trust for minor children in the will?',
                field: 'estateQuestion___will_minorTrust',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'POA',
        subtitle: null,
        questions: [
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Has POA?',
                field: 'estateQuestion___poa_hasPoa',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Agents',
                field: 'estateQuestion___poa_agentNames',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Does the POA list 2 people as agents to serve at the same time (aka co-agents)?',
                field: 'estateQuestion___poa_coAgents',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Can the co-agents act independently?',
                field: 'estateQuestion___poa_coAgentsIndep',
                version: [1, 2, 3, 4],
                indent: 4,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is the POA springing?',
                field: 'estateQuestion___poa_springing',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is it a statutory power POA? (i.e. does it say the Agent gets all the powers in the state statute?)',
                field: 'estateQuestion___poa_statutory',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there a power related to digital asset management?',
                field: 'estateQuestion___poa_digitalAssets',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Does it include powers for gifting?',
                field: 'estateQuestion___poa_giftingPowers',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Does it include powers for tax related decisions?',
                field: 'estateQuestion___poa_taxPowers',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Are there powers related to trust management?',
                field: 'estateQuestion___poa_trustManagement',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: false,
                isDynamic: false,
                label: 'Are there powers allowing changes to beneficiary designations?',
                field: 'estateQuestion___poa_powerChanges',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'Healthcare',
        subtitle: null,
        questions: [
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Has HealthCare?',
                field: 'estateQuestion___hc_hasHc',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: false,
                isDynamic: true,
                label: 'Does the Healthcare POA list 2 people as agents to serve at the same time (aka co-agents)?',
                field: 'estateQuestion___hc_coAgents',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'Revocable Trust',
        subtitle: null,
        questions: [
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Has Revocable Trusts?',
                field: 'estateQuestion___rev_hasRev',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Name',
                field: 'estateQuestion___rev_trustName',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is it a joint trust?',
                field: 'estateQuestion___rev_jointTrust',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Has the trust been amended?',
                field: 'estateQuestion___rev_amended',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Amendment', 'Restatement'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is EACH amendement a restatement or just an amendment? (Restatement, amendment)',
                field: 'estateQuestion___rev_amendmentOrRestatement',
                version: [1, 2, 3, 4],
                indent: 4,
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'What is the original trust date?',
                field: 'estateQuestion___rev_trustDate',
                version: [1, 2, 3, 4],
                indent: 4,
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'What is the trust amendment date(s)?',
                field: 'estateQuestion___rev_amendmentDate',
                version: [1, 2, 3, 4],
                indent: 4,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is the trust or most recent amendment more than 5 years old?',
                field: 'estateQuestion___rev_trust5YearsOld',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is the state listed as the "governing law provision" in trust document different than the state of residence in the General Questionnaire?',
                field: 'estateQuestion___rev_diffGoverningLaw',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'Revocable Trust',
        subtitle: 'Trustees',
        questions: [
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Who are the trustees of the main trust?',
                field: 'estateQuestion___rev_mainTrustees',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Who are the successor trustees of the main trust?',
                field: 'estateQuestion___rev_successorTrustees',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Are there co-trustees?',
                field: 'estateQuestion___rev_coTrustees',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Do they have the power to act independently?',
                field: 'estateQuestion___rev_indepTrustees',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Can they continue as sole trustee?',
                field: 'estateQuestion___rev_soleTrustee',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there a "no available trustee" clause?',
                field: 'estateQuestion___rev_noTrusteeClause',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Are the resulting trust trustees the same as the main trust trustees?',
                field: 'estateQuestion___rev_matchingTrustees',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'Revocable Trust',
        subtitle: 'Distributions',
        questions: [
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Are there any specific disitrbutions (specific bequests)?',
                field: 'estateQuestion___rev_specificDistributions',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there a cash bequest?',
                field: 'estateQuestion___rev_cashBequest',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is the specific bequest a house or real estate property?',
                field: 'estateQuestion___rev_realEstate',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Which Property & Beneficiaries?',
                field: 'estateQuestion___rev_realEstateDetail_JSON',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is the specific bequest personal property? (cars, jewelry, art, collectibles, furniture, dishes, etc)',
                field: 'estateQuestion___rev_personalProperty',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Which Property & Beneficiaries?',
                field: 'estateQuestion___rev_personalPropertyDetail_JSON',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is the specific bequest a business?',
                field: 'estateQuestion___rev_businessInTrust',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Per capita', 'By right of representation', 'Per stirpes'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Who are the contingent beneficiaries?',
                field: 'estateQuestion___rev_contingentBeneficiaries',
                version: [1, 2, 3, 4],
                addOptions: true,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there a catastrophe clause?',
                field: 'estateQuestion___rev_catastropheClause',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Are there any charitable distributions?',
                field: 'estateQuestion___rev_charitableDistributions',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Dollar amount', 'Percentage'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is the gift specified as a fixed dollar amount or a percentage?',
                field: 'estateQuestion___rev_charitableDistributionsType',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there direction on which assets should be used for the charitable distribution?',
                field: 'estateQuestion___rev_assetDirections',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['In Trust', 'Outright'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is the residuary estate passed outright or in trust?',
                field: 'estateQuestion___rev_residuaryEstate',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Outright - Beneficiaries',
                field: 'estateQuestion___rev_residuaryEstateBeneficiaries',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Does the Trust contain any provisions that address managing business interest?',
                field: 'estateQuestion___rev_hasBusinessManagementProvisions',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'Revocable Trust',
        subtitle: 'Resulting Trusts',
        questions: [
            {
                width: 'small',
                options: ['A/B Trust', 'A/B/C Trust', 'Disclaimer Trust', 'In Trust', 'Outright'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Upon the death of the first grantor, how is descendant\'s share passed?',
                field: 'estateQuestion___rev_descendantShare',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there a Marital trust created?',
                field: 'estateQuestion___rev_maritalTrustCreated',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is the Marital Trust a QTIP?',
                field: 'estateQuestion___rev_maritalTrustQTIP',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ["Descendant's/In Trust", 'Outright', 'NA'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'What happens to the resulting trust assests afer the surviving spouse\'s death?',
                field: 'estateQuestion___rev_afterSpouseDeath',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Lifetime', 'Age Based'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is the descendant’s trust a lifetime trust, or does it include age-based distribution provisions?',
                field: 'estateQuestion___rev_resultingDescendantsTrustLifetimeOrAgeBased',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ["Survivor's Trust", 'Does not say', 'NA'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Upon the death of the first grantor, how is surviving spouse\'s share passed?',
                field: 'estateQuestion___rev_spouseShare',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ["Descendant's/In Trust", 'Outright', 'NA'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'What happens to the surviving spouse\'s assets upon their death?',
                field: 'estateQuestion___rev_spouseAssetsPostDeath',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Lifetime', 'Age Based'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is the descendant’s trust a lifetime trust, or does it include age-based distribution provisions?',
                field: 'estateQuestion___rev_survivorDescendantsTrustLifetimeOrAgeBased',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Does the resulting trust have trustees different from those of the main trust?',
                field: 'estateQuestion___rev_resultingTrustDifferentTrustees',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Who are the trustees?',
                field: 'estateQuestion___rev_resultingTrustees',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Who are the successor trustees?',
                field: 'estateQuestion___rev_resultingSuccessorTrustees',
                version: [1, 2, 3, 4],
                indent: 8,
            },
        ],
    },
    {
        title: 'Revocable Trust',
        subtitle: 'Provisions/Clauses',
        questions: [
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is there a governing law provision in the trust? (i.e does it say what state law applies to the trust?)',
                field: 'estateQuestion___rev_governingLaw',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: CONST_STATES,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'What state?',
                field: 'estateQuestion___rev_state',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there a minor’s clause in the revocable trust?',
                field: 'estateQuestion___rev_minorClause',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Does the beneficiary receive a power of appointment over the trust assets? (could be different per each resulting trust)',
                field: 'estateQuestion___rev_powerOfAppointment',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Does the revocable trust create a trust that provides for distributions under a HEMS standard?',
                field: 'estateQuestion___rev_hemStandardTrust',
                version: [1, 2, 3, 4],
                index: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Does the trust contain a clause about what happens if the surviving spouse re-marries?',
                field: 'estateQuestion___rev_spouseRemarriageClause',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there a digital assets provision allowing the trustee to manage online accounts and data?',
                field: 'estateQuestion___rev_digitalAssets',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there a trustee power to direct tax efficient distributions?',
                field: 'estateQuestion___rev_taxEfficientDistributions',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Does the main trust direct the trustee how to apportion tax payments after the grantor\'s death?',
                field: 'estateQuestion___rev_taxApportion',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Do any of the resulting trusts direct the trustee how to apportion tax payments after the grantor\'s death?',
                field: 'estateQuestion___rev_taxPayments',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: false,
                isDynamic: false,
                label: 'Is the trustee permitted to distribute capital gains to the beneficiary as part of the income distribution? (could be different per each resulting trust)',
                field: 'estateQuestion___rev_capitalGains',
                version: [1, 2, 3, 4],
            },
        ],
    },
];



const IrrevocableTrustsStructure = [
    /* {
        title: 'Irrevocable Trust Extra',
        subtitle: null,
        index: 0,
        questions: [
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Has Irrevocable Trust?',
                field: 'estateQuestion___irrev_hasIrrev',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Irrevocable Trust Quantity',
                field: 'estateQuestion___irrev_number',
                version: [1, 2, 3, 4],
                // display: 'hidden',
            },
        ],
    }, */
    {
        title: 'Irrevocable Trust',
        subtitle: null,
        index: 0,
        questions: [
            /* {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Has Irrevocable Trust?',
                field: 'estateQuestion___irrev_hasIrrev',
                version: [1, 2, 3, 4],
            }, */
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Name',
                field: 'estateQuestion___irrev_trustName',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Has the trust been amended/modified?',
                field: 'estateQuestion___irrev_amended',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is the state listed as the "governing law provision" in trust document different than the state of residence in the General Questionnaire?',
                field: 'estateQuestion___irrev_diffGoverningLaw',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'Irrevocable Trust',
        subtitle: 'Trustees',
        index: 0,
        questions: [
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Who are the trustees of the main trust?',
                field: 'estateQuestion___irrev_mainTrustees',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Who are the successor trustees of the main trust?',
                field: 'estateQuestion___irrev_successorTrustees',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Are there co-trustees?',
                field: 'estateQuestion___irrev_coTrustees',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Do they have the power to act independently?',
                field: 'estateQuestion___irrev_indepTrustees',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Can they continue as sole trustee?',
                field: 'estateQuestion___irrev_soleTrustee',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there a "no available trustee" clause?',
                field: 'estateQuestion___irrev_noAvailableClause',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'Irrevocable Trust',
        subtitle: 'Distributions',
        index: 0,
        questions: [
            {
                width: 'small',
                options: ['Outright', 'In Trust', 'Both'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Upon the grantor\'s death, how are assets passed?',
                field: 'estateQuestion___irrev_assetsPostGrantor',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Upon the grantor\'s death, who are the beneficiaries?',
                field: 'estateQuestion___irrev_grantorBeneficiaries',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Per capita', 'By right of representation', 'Per stirpes'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Who are the contingent beneficiaries?',
                field: 'estateQuestion___irrev_contingentBeneficiaries',
                version: [1, 2, 3, 4],
                addOptions: true,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Is there a catastrophe clause?',
                field: 'estateQuestion___irrev_catastropheClause',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'Irrevocable Trust',
        subtitle: 'Provisions/Clauses',
        index: 0,
        questions: [
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is there a governing law provision in the trust? (i.e does it say what state law applies to the trust?)',
                field: 'estateQuestion___irrev_governingLaw',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: CONST_STATES,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'What state?',
                field: 'estateQuestion___irrev_state',
                version: [1, 2, 3, 4],
                indent: 8,
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Does the beneficiary receive a power of appointment over the trust assets? (could be different per each resulting trust)',
                field: 'estateQuestion___irrev_powerOfAppointment',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'Does it have a modification provision?',
                field: 'estateQuestion___irrev_hasModificationClause',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: undefined,
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: false,
                label: 'If yes, this is what you have in your modification provision',
                indent: 8,
                field: 'estateQuestion___irrev_modificationClause',
                version: [1, 2, 3, 4],
            },
        ],
    },
    {
        title: 'Irrevocable Trust',
        subtitle: 'Specific Types of Irrevocable Trusts',
        index: 0,
        questions: [
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is it a Qualified Personal Residence Trust (QPRT)?',
                field: 'estateQuestion___irrev_qprt',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is it a Grantor Retained Annuity Trust (GRAT)?',
                field: 'estateQuestion___irrev_grat',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is it a Grantor Retained Unitrust (GRUT)?',
                field: 'estateQuestion___irrev_grut',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is it a Charitale Remiander Trust (CRT)?',
                field: 'estateQuestion___irrev_crt',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is it a Charitable Lead Trust (CLT)?',
                field: 'estateQuestion___irrev_clt',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is it an Intentionally Defective Grantor Trust (IDGT)?',
                field: 'estateQuestion___irrev_idgt',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is it a Dynasty Trust?',
                field: 'estateQuestion___irrev_dynastyTrust',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is it a Spousal Lifetime Access Trust (SLAT)?',
                field: 'estateQuestion___irrev_slat',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['Yes', 'No'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Is it an Irrevocable Life Insurance Trust (ILIT)?',
                field: 'estateQuestion___irrev_ilit',
                version: [1, 2, 3, 4],
            },
            {
                width: 'small',
                options: ['One', 'Two'],
                withoutPages: true,
                ignoreBorder: true,
                isDynamic: true,
                label: 'Does it have two Grantors or just one?',
                field: 'estateQuestion___irrev_grantorCount',
                version: [1, 2, 3, 4],
                indent: 8,
            },
        ],
    },
];



export const BuildEstateQuestionnaireStructure = (irrevNumber = 0) => {
    if (irrevNumber < 1) return GeneralQuestionnaireStructure;

    const struct = JSON.parse(JSON.stringify(IrrevocableTrustsStructure));
    const irrevStructure = [
        {
            title: 'Irrevocable Trust Extra',
            subtitle: null,
            index: 0,
            questions: [
                {
                    width: 'small',
                    options: ['Yes', 'No'],
                    withoutPages: true,
                    ignoreBorder: true,
                    isDynamic: true,
                    label: 'Has Irrevocable Trust?',
                    field: 'estateQuestion___irrev_hasIrrev',
                    version: [1, 2, 3, 4],
                },
                {
                    width: 'small',
                    options: undefined,
                    withoutPages: true,
                    ignoreBorder: true,
                    isDynamic: true,
                    label: 'Irrevocable Trust Quantity',
                    field: 'estateQuestion___irrev_number',
                    version: [1, 2, 3, 4],
                    // display: 'hidden',
                },
            ],
        },
    ];

    for (let i = 1; i <= irrevNumber; i++) {
        struct.forEach((section) => {
            let newSection = JSON.parse(JSON.stringify(section));
            newSection.index = i;
            newSection.questions.forEach((question) => {
                question.field = question.field + '_' + i;
            });
            irrevStructure.push({...newSection});
            newSection = {};
        });
    }

    const structure = [...GeneralQuestionnaireStructure, ...irrevStructure];
    return structure;
};





export default GeneralQuestionnaireStructure;